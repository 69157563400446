import React from 'react';
import Icon, { IIcon } from './Icon';

const IconDrops: React.FC<IIcon> = ({ color, ...props }) => (
  <Icon viewBox="0 0 64 64" {...props}>
    <path
      d="M41 21.7632C40.0115 20.9271 39.5594 19.8966 39.3199 18.18C38.9499 15.6 36.2199 13.39 33.3699 12.04L12.8999 2.38001C9.35989 0.710012 6.00989 4.65001 3.58989 10.68C1.16989 16.71 1.26989 21.43 5.60989 23.48L24.5899 32.44C29.9199 34.96 31.5099 33.94 33.4499 32.23C34.7985 31.0413 35.4802 30.8046 37 31.3518"
      stroke={color}
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.8498 38.69C52.3998 32.79 53.5298 28.73 53.4398 26.35C51.5098 25.29 46.8298 22.96 45.6298 22.66C42.6398 26.15 40.5698 31.64 40.6598 34.48C45.1098 37.16 47.6598 37.94 47.6598 37.94"
      stroke={color}
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54 38.61C55.16 38.6 56.96 39.02 59.26 39.57C59.97 39.74 60.69 39.34 60.97 38.61C61.35 37.62 61.65 36.88 61.88 36.33C62.18 35.61 61.95 34.75 61.34 34.32C59.68 33.14 58.61 32.4101 57 30.0001"
      stroke={color}
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.24977 25.2C8.34977 21.11 14.5598 6.76003 17.0898 4.37003"
      stroke={color}
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.1899 33.2C28.1199 22.32 31.5699 16.89 34.0599 12.38"
      stroke={color}
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1349 20.8361L16 14L19.577 17.6803C19.577 17.6803 20.2334 16.9262 20.4959 15.2295L25 23L21.2999 19.1475"
      stroke={color}
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.0499 47.93C57.2999 49.41 60.7299 51.64 61.5099 55.63C62.1399 58.41 60.5799 61.21 58.0199 61.9C58.0099 61.9 57.9999 61.91 57.9899 61.91C56.5299 62.27 52.6999 61.68 52.0999 57.5C51.4999 53.32 53.0899 51.29 53.0899 51.29"
      stroke={color}
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default IconDrops;
