import styled from 'styled-components';
import { EnumFontFamily, TextStylesSm, TextStylesLg, EnumTextType } from '../../theme';
import { ITextStyle, Text } from './Text';

type lvl = 1 | 2 | 3 | 4 | 5;

interface ITitle {
  lvl?: lvl;
  color?: string;
}

const getTitleTextStyle = (lvl: lvl): ITextStyle => ({
  sm: TextStylesSm[EnumFontFamily.introscript + EnumTextType[`h${lvl}`]],
  lg: TextStylesLg[EnumFontFamily.introscript + EnumTextType[`h${lvl}`]],
});

const Title = styled(Text).attrs(({ lvl = 1, ...props }) => ({
  as: `h${lvl}`,
  textStyle: getTitleTextStyle(lvl as lvl),
  ...props,
}))<ITitle>``;

export default Title;
