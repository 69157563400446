import React from 'react';
import styled from 'styled-components';
import { getVwLg, getVwSm, media, EnumScreens } from 'theme/helpers/css';
import Title from 'Components/Typography/Title';
import { HandText, Highlight, handTextStyle } from 'Components/Typography/Text';
import eye from './img/eye.svg';
import { EnumFontFamily, TextStylesSm, EnumTextType } from 'theme';
import { Arrow } from './TextBlock';

const StyledTitle = styled(Title).attrs({ lvl: 5 })`
  font-size: ${getVwSm('42px')};
  line-height: ${getVwSm('58px')};

  ${media.up(EnumScreens.lg)} {
    margin-bottom: ${getVwLg('28px')};
    font-size: ${getVwLg('72px')};
    line-height: ${getVwLg('58px')};
  }
`;

const StyledText = styled(HandText).attrs({
  textStyle: { ...handTextStyle, sm: TextStylesSm[EnumFontFamily.fewriter + EnumTextType.h3] },
})`
  ${media.down(EnumScreens.sm)} {
    width: ${getVwSm('260px')};
  }
`;

const Illustration = styled.img`
  max-width: 100%;

  ${media.down(EnumScreens.sm)} {
    transform: scaleX(-1);
  }

  ${media.up(EnumScreens.lg)} {
    width: ${getVwLg('516px')};
    margin-top: ${getVwLg('22px')};
    transform: rotate(-3deg);
  }
`;

const IllustrationBlock: React.FC = () => (
  <div>
    <StyledTitle>Фокусировка зрения</StyledTitle>
    <StyledText textStyle={{ ...handTextStyle, sm: TextStylesSm[EnumFontFamily.fewriter + EnumTextType.h3] }}>
      управляется <Highlight>цилиарной мышцей</Highlight> глаза
    </StyledText>
    <Illustration src={eye} width="516" />
    <Arrow />
  </div>
);

export default IllustrationBlock;
