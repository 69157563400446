import { Link } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'theme/colors';
import { getVwLg, getVwSm, mediaLgUp } from 'theme/helpers/css';
import Flex, { IFlex } from './Flex';
import { HandText } from './Typography';
import { TransitionLink } from 'Containers/PageTransition';

export enum EnumButtonSizes {
  xs,
  sm,
  md,
  lg,
  xl,
}

interface IButton extends IFlex {
  to?: string;
  href?: string;
  onClick?: (e: any) => void;
  size?: EnumButtonSizes;
  className?: string;
  theme?: any;
}

const Container = styled(Flex).attrs(props => ({
  center: true,
  middle: true,
  className: props.className,
  ...props,
}))`
  width: max-content;
  height: ${getVwSm('36px')};
  padding: 0 ${getVwSm('24px')};
  border-radius: ${getVwSm('12px')};
  background-color: ${props => props.theme.button?.bgColor || colors.moonYellow};
  text-decoration: none;
  cursor: pointer;

  ${mediaLgUp} {
    height: ${getVwLg('50px')};
    padding: 0 ${getVwLg('24px')};
    border-radius: ${getVwLg('18px')};
  }

  ${HandText} {
    color: ${props => props.theme.button?.color || colors.deepBlue}!important;
  }
`;

const Button: React.FC<IButton> = ({ children, ...props }) => {
  const as = props.to ? TransitionLink : props.href ? 'a' : 'button';
  const target = as === 'a' && '_blank';

  return (
    <Container {...{ ...props, as, target }}>
      <HandText>{children}</HandText>
    </Container>
  );
};

export const ToggleButton = styled(Button)<IButton & { active: boolean }>`
  ${({ active, theme }) =>
    !active &&
    css`
      border-width: ${getVwSm('2px')};
      border-style: solid;
      border-color: ${theme.button?.bgColor};
      background-color: ${theme.button?.color};

      ${HandText} {
        color: ${theme.button?.bgColor}!important;
      }

      ${mediaLgUp} {
        border-width: ${getVwLg('2px')};
      }
    `}
`;

export default Button;
