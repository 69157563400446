import React from 'react';
import styled from 'styled-components';
import { HandText } from 'Components/Typography/Text';
import Title from 'Components/Typography/Title';
import Button, { EnumButtonSizes } from 'Components/Button';
import { getVwLg, getVwSm, media, mediaLgUp, mediaLgDown, EnumScreens } from 'theme/helpers/css';
import { EnumFontFamily, TextStylesLg, EnumTextType } from 'theme';
import arrow from './img/arrow.svg';
import Flex from 'Components/Flex';

const Container = styled(Flex).attrs({
  column: true,
})`
  margin: 0 ${getVwSm('10px')};

  ${media.up(EnumScreens.lg)} {
    width: max-content;
    margin: 0;

    ${HandText} {
      max-width: ${getVwLg('447px')};
    }
  }
`;

const StyledTitle = styled(Title)`
  ${media.down(EnumScreens.sm)} {
    margin-left: ${getVwSm('8px')};
  }

  ${media.up(EnumScreens.lg)} {
    max-width: ${getVwLg('478px')};
    margin-top: ${getVwLg('12px')};
    ${TextStylesLg[EnumFontFamily.introscript + EnumTextType.h3]}
  }
`;

const TitleContainer = styled.div`
  margin-bottom: ${getVwSm('12px')};
  display: flex;
  align-items: center;

  & > :first-child {
    flex-shrink: 0;
  }

  ${media.up(EnumScreens.lg)} {
    margin-bottom: ${getVwLg('8px')};
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Arrow = styled.img.attrs({
  src: arrow,
  width: '180',
  height: '40',
})`
  margin: ${getVwSm('24px')} 0;
  width: ${getVwSm('180px')};
  height: ${getVwSm('40px')};

  ${media.up(EnumScreens.lg)} {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  margin: ${getVwSm('20px')} 0;

  ${mediaLgDown} {
    align-self: center;
  }

  ${mediaLgUp} {
    margin: ${getVwLg('20px')} 0 0;
  }
`;

export interface ITextBlock {
  title: string;
  text: string;
  icon: React.ReactNode;
  link?: {
    text: string;
    to: string;
  };
  arrow?: boolean;
}

const TextBlock: React.FC<ITextBlock> = ({ title, text, icon, link, arrow = false }) => (
  <Container>
    <TitleContainer>
      {icon}
      <StyledTitle lvl={5}>{title}</StyledTitle>
    </TitleContainer>

    <HandText>{text}</HandText>

    {link && (
      <StyledButton to={link.to} size={EnumButtonSizes.xl}>
        {link.text}
      </StyledButton>
    )}

    {arrow && <Arrow />}
  </Container>
);

export default TextBlock;
