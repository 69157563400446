import React from 'react';
import Icon, { IIcon } from './Icon';

const IconClock: React.FC<IIcon> = ({ color, ...props }) => (
  <Icon viewBox="0 0 6.4 6.4" {...props}>
    <defs>
      <style>{`
        .cls-1{
          fill: ${color};
        }
      `}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M4.46,3.48l-1-.42,0,0h0c.21-.52.43-1,.63-1.57a.18.18,0,0,0-.13-.22.18.18,0,0,0-.22.12c-.2.53-.41,1-.63,1.58H3L3,3H3l0,0a.14.14,0,0,0,0,.06v0s0,0,0,.06,0,0,0,.07,0,0,0,.08H3a.1.1,0,0,0,0,.05v0l0,0,.06,0h.18l.06,0,0,0,1,.41a.19.19,0,0,0,.23-.13A.2.2,0,0,0,4.46,3.48Z"
        />
        <path
          className="cls-1"
          d="M6.37,2.71A3.25,3.25,0,0,0,4,.08,3.85,3.85,0,0,0,3.48,0c-.08,0-.13.05-.16.12a.13.13,0,0,0-.1,0,3.18,3.18,0,0,0-3,2,3.17,3.17,0,0,0,.93,3.57,3.35,3.35,0,0,0,1.73.73A3.13,3.13,0,0,0,4.72,6,3.16,3.16,0,0,0,6.05,4.67,3.39,3.39,0,0,0,6.37,2.71ZM4.88,5.48a2.88,2.88,0,0,1-3.17.14,2.81,2.81,0,0,1-1.29-3A2.82,2.82,0,0,1,3.22.44.16.16,0,0,0,3.38.32a.18.18,0,0,0,.1,0,2.89,2.89,0,0,1,2.45,2A2.85,2.85,0,0,1,4.88,5.48Z"
        />
      </g>
    </g>
  </Icon>
);

export default IconClock;
