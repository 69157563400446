import React from 'react';
import Icon, { IIcon } from './Icon';

const IconShinyEye: React.FC<IIcon> = ({ color, ...props }) => (
  <Icon viewBox="0 0 65 64" {...props}>
    <path
      d="M32.33 18.25C27.97 18.4 23.32 19.75 21.41 24.08C19.15 29.21 20.45 35.31 24.88 38.85C28.84 42.01 34.27 42.52 38.68 39.98C43.23 37.35 45.55 32.15 44.73 27C43.82 21.21 38.81 16.88 33 16.57C31.55 16.49 31.56 18.74 33 18.82C37.13 19.04 40.9 21.87 42.15 25.83C43.46 29.97 42.15 34.63 38.67 37.3C35.36 39.83 30.9 40.06 27.36 37.88C23.5 35.51 21.67 30.81 22.92 26.47C23.53 24.36 24.7 22.69 26.69 21.7C28.42 20.83 30.41 20.59 32.32 20.52C33.78 20.45 33.78 18.2 32.33 18.25Z"
      fill={color}
    />
    <path
      d="M8.41006 19.81C5.81006 21.56 3.33006 23.48 0.960064 25.52C0.500064 25.92 0.550064 26.7 0.960064 27.11C1.42006 27.57 2.09006 27.51 2.55006 27.11C4.81006 25.18 7.08006 23.42 9.55006 21.75C10.0501 21.41 10.2701 20.76 9.95006 20.21C9.66006 19.72 8.92006 19.47 8.41006 19.81Z"
      fill={color}
    />
    <path
      d="M60.8901 32.53C61.3901 31.81 61.9301 31.12 62.3601 30.35C62.6201 29.89 62.5201 29.37 62.1801 28.99C57.4701 23.5 51.9701 18.43 45.4101 15.23C39.5001 12.36 33.0101 11.28 26.5001 12.24C22.4201 12.85 18.5101 14.26 14.8101 16.07C13.5101 16.71 14.6501 18.65 15.9501 18.01C21.8601 15.12 28.4201 13.48 35.0101 14.34C41.2101 15.15 46.9501 18.1 51.8001 21.96C55.0201 24.52 57.9101 27.46 60.5901 30.58C60.5301 30.13 60.4701 29.67 60.4101 29.22C59.9801 29.99 59.4401 30.68 58.9401 31.4C58.1201 32.59 60.0701 33.71 60.8901 32.53Z"
      fill={color}
    />
    <path
      d="M29.9601 46.6C35.0001 46.75 40.0901 46.2 44.8901 44.6C49.4701 43.07 53.2701 40.46 56.7401 37.13C57.7901 36.13 56.2001 34.54 55.1501 35.54C51.8401 38.71 48.2601 41.16 43.8601 42.57C39.3601 44.01 34.6701 44.49 29.9601 44.35C28.5101 44.31 28.5101 46.56 29.9601 46.6Z"
      fill={color}
    />
    <path
      d="M0.169946 31.61C4.94995 39.56 13.4399 44.1 22.3599 45.78C23.7799 46.05 24.3799 43.88 22.9599 43.61C14.6099 42.04 6.59995 37.94 2.10995 30.47C1.36995 29.23 -0.580054 30.36 0.169946 31.61Z"
      fill={color}
    />
    <path
      d="M13.7799 27.16C13.4099 26.09 13.0399 25.01 12.4999 24.01C11.9599 23.01 11.2599 22.09 10.3199 21.46C9.02989 20.58 7.42989 20.34 5.88989 20.12C5.88989 20.12 9.14989 19.37 11.0699 17.41C13.0999 15.34 12.9199 12 12.9199 12C13.0799 13.97 14.0099 15.86 15.4499 17.18C16.8899 18.5 18.8399 19.23 20.7899 19.19C20.7899 19.19 17.9099 19.3 15.9099 21.86C14.7299 23.37 14.2699 25.34 14.0499 27.25"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.57 39.37C57.28 38.52 56.98 37.66 56.55 36.87C56.12 36.08 55.56 35.34 54.82 34.84C53.79 34.14 52.52 33.95 51.3 33.78C51.3 33.78 53.89 33.18 55.42 31.62C57.03 29.98 56.89 27.32 56.89 27.32C57.02 28.89 57.7601 30.39 58.9001 31.44C60.0501 32.49 61.6 33.07 63.14 33.04C63.14 33.04 60.85 33.13 59.26 35.16C58.32 36.36 57.96 37.92 57.78 39.44"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.7199 51.93C25.4299 51.08 25.13 50.22 24.7 49.43C24.27 48.64 23.7099 47.9 22.9699 47.4C21.9399 46.7 20.67 46.51 19.45 46.34C19.45 46.34 22.04 45.74 23.57 44.18C25.18 42.54 25.04 39.88 25.04 39.88C25.17 41.45 25.9099 42.95 27.0499 44C28.1999 45.05 29.75 45.63 31.29 45.6C31.29 45.6 29 45.69 27.41 47.72C26.47 48.92 26.11 50.48 25.93 52"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default IconShinyEye;
