import React from 'react';
import Layout from 'Containers/Layout';
import NightDrops from 'Containers/NightDrops';
import Footer, { FooterThemes } from 'Components/Footer';
import Navbar, { NavbarPages } from 'Components/Navbar';
import { EnumPages } from '../constants';
import colors from 'theme/colors';

const theme = {
  bgColor: colors.deepBlue,
  body: {
    color: colors.moonYellow,
  },
  button: {
    color: colors.deepBlue,
    bgColor: colors.moonYellow,
  },
};

const NightdropsPage: React.FC = () => (
  <Layout theme={theme}>
    <Navbar page={NavbarPages.NIGHTDROPS} />
    <NightDrops />
    <Footer theme={FooterThemes[EnumPages.NIGHTDROPS]} />
  </Layout>
);

export default NightdropsPage;
