import React from 'react';
import Icon, { IIcon } from './Icon';

const IconMask: React.FC<IIcon> = ({ color, ...props }) => (
  <Icon viewBox="0 0 72 64" {...props}>
    <path
      d="M35.178 41.6604C33.8043 39.7995 31.1299 39.485 28.8779 39.9865C26.626 40.488 24.5495 41.6261 22.2959 42.1172C17.4849 43.168 12.285 40.9611 9.26802 37.0441C6.25107 33.1271 5.34925 27.7399 6.40733 22.8555C7.07802 19.7588 8.56595 16.7439 11.0376 14.8024C13.5092 12.8609 16.7468 12.1584 19.8537 12.2548C22.9606 12.3512 25.9898 13.1811 28.9795 14.0253C36.975 16.2909 44.9246 18.7131 52.8219 21.2999C57.0895 22.695 61.6275 24.35 64.2107 28.0681C66.8849 31.9144 66.8117 37.1582 65.4569 41.6963C64.5795 44.6294 63.1583 47.4932 60.8476 49.4673C57.3212 52.4795 52.168 52.9339 47.8001 51.5471C43.4321 50.1602 39.7333 47.1693 36.511 43.8776"
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </Icon>
);

export default IconMask;
