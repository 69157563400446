import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import TextBlock from './TextBlock';
import { ITextBlock } from './TextBlock';
import IllustrationBlock from './IllustrationBlock';
import IconDrops from 'Components/Icons/IconDrops';
import IconClock from 'Components/Icons/IconClock';
import IconMask from 'Components/Icons/IconMask';
import IconShinyEye from 'Components/Icons/IconShinyEye';
import arrowsPc from './img/arrows-pc.svg';
import { getVwLg, getVwSm, media, EnumScreens, mediaLgDown } from 'theme/helpers/css';
import { NBSP, URLs } from '../../constants';
import Disclaimer, { EnumDisclaimers } from 'Components/Disclaimer';
import Box from 'Components/Box';
import Logo from 'Components/Logo';

const Container = styled.div`
  padding: ${getVwSm('15px')};
  background-color: ${colors.deepBlue};
  color: ${colors.moonYellow};

  display: flex;
  flex-direction: column;

  ${mediaLgDown} {
    padding-top: ${getVwSm('112px')};
  }

  ${media.up(EnumScreens.lg)} {
    padding: ${getVwLg('114px')} ${getVwLg('160px')};
    background: ${colors.deepBlue} url(${arrowsPc}) center/ ${getVwLg('382.85px')} no-repeat;
    background-position-y: ${getVwLg('385px')};

    & > * {
      transform: rotate(3deg);
    }

    & > :nth-child(even) {
      align-self: flex-end;
      transform: rotate(-3deg);
    }

    & > :nth-child(2) {
      margin-top: ${getVwLg('10px')};
    }
    & > :nth-child(3) {
      margin-top: ${getVwLg('-38px')};
    }
    & > :nth-child(4) {
      margin-top: ${getVwLg('48px')};
    }
    & > :nth-child(5) {
      margin-top: ${getVwLg('-158px')};
    }
  }
`;

const iconProps = {
  w: { sm: getVwSm('48px'), lg: getVwLg('64px') },
  h: { sm: getVwSm('48px'), lg: getVwLg('64px') },
  color: colors.moonYellow,
};

const blocks: ITextBlock[] = [
  {
    title: 'Длительная работа',
    icon: <IconClock {...iconProps} />,
    text: `за${NBSP}компьютером обычно вызывает перенапряжение глазных мышц, что создает вероятность возникновения зрительных нарушений.`,
    link: {
      text: 'Читать подробнее',
      to: URLs.problem,
    },
  },
  {
    title: 'Во время сна',
    icon: <IconMask {...iconProps} />,
    text: `Мышцам глаза иногда сложно самостоятельно вернуться в${NBSP}нормальное состояние, поэтому порой наутро усталость глаз может сохраняться.`,
  },
  {
    title: `Как перезагрузить свои${NBSP}глаза?`,
    icon: <IconDrops {...iconProps} />,
    text: `Существуют специальные капли, которые способны дать перенапряженным мышцам глаз необходимый отдых и${NBSP}тем самым положительно влияют на${NBSP}зрительную функцию.`,
  },
  {
    title: 'Подобрать капли!',
    icon: <IconShinyEye {...iconProps} />,
    text: `Если вы${NBSP}испытываете похожие симптомы "усталости" ваших глаз, посоветуйтесь с${NBSP}врачом-офтальмологом.`,
    link: {
      text: 'Записаться на приём',
      to: URLs.makeAppointment,
    },
  },
];

const NightDrops: React.FC = () => (
  <Box>
    <Logo />
    <Container>
      <IllustrationBlock />
      {blocks.map((props, i) => (
        <>
          <TextBlock key={props.title} arrow={i < blocks.length - 1} {...props} />
        </>
      ))}
    </Container>
    <Disclaimer type={EnumDisclaimers.SEE_DOCTOR} />
  </Box>
);

export default NightDrops;
